import React from "react"
import Layout from "../components/Layout"
import Gallery from "../components/gallery/Gallery"
export default function GalleryPage() {
  return (
    <Layout pageTitle="Gallery">
      <Gallery />
    </Layout>
  )
}

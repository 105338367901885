import React, { useState, useEffect } from "react"
import { Spinner } from "theme-ui"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLongArrowAltLeft } from "@fortawesome/free-solid-svg-icons"
import { theme } from "../../globalStyles"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import guy from "../../assets/guy.svg"

export default function Album({ albumOpenID, backToAlbumView }) {
  const [albumData, setAlbumData] = useState({
    albums: null,
    isLoading: true,
  })
  const [lightbox, setLightbox] = useState({
    photoIndex: 0,
    isOpen: false,
  })
  // const [images, setImages] = useState([])

  const fetchData = async () => {
    await fetch(
      `https://www.gonation.com/api/proxy/v2/albums/${albumOpenID}/images`,
      {
        mode: "cors",
        Accept: "application/json",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then(res => res.json())
      .then(data => {
        setAlbumData({ ...albumData, albums: data, isLoading: false })
        // setImages(data.albums.items)
      })
      .catch(e => {
        console.log("error : ", console.e)
        setAlbumData({ ...albumData, isLoading: false })
      })
  }

  useEffect(() => {
    fetchData()
    return () => {}
  }, [])

  const handleThumbnailCLick = idx => {
    setLightbox({
      isOpen: true,
      photoIndex: idx,
    })
  }

  return (
    <div>
      <BackToGallery
        className="back-to-gallery-btn"
        onClick={() => {
          backToAlbumView()
        }}
      >
        <FontAwesomeIcon icon={faLongArrowAltLeft} /> Back to Albums
      </BackToGallery>
      <AlbumContainer>
        {!albumData.isLoading ? (
          <>
            {albumData.albums.items.map(({ cloudinaryId }, idx) => (
              <AlbumImage
                onClick={() => {
                  setLightbox({
                    photoIndex: idx,
                    isOpen: true,
                  })
                }}
                key={cloudinaryId}
                src={`https://res.cloudinary.com/gonation/w_275,c_fit,fl_lossy,f_auto,q_auto/${cloudinaryId}`}
              />
            ))}
            {lightbox.isOpen && (
              <Lightbox
                imageCaption={
                  albumData.albums.items[lightbox.photoIndex].name
                    ? albumData.albums.items[lightbox.photoIndex].name
                    : ""
                }
                mainSrc={`https://res.cloudinary.com/gonation/w_1000,c_fit,fl_lossy,f_auto,q_auto/${
                  albumData.albums.items[lightbox.photoIndex].cloudinaryId
                }`}
                nextSrc={
                  `https://res.cloudinary.com/gonation/w_1000,c_fit,fl_lossy,f_auto,q_auto/${
                    albumData.albums.items[
                      (lightbox.photoIndex + 1) % albumData.albums.items.length
                    ].cloudinaryId
                  }`
                  // albumData.albums.items[
                  //   (lightbox.photoIndex + 1) % albumData.albums.items.length
                  // ]
                }
                prevSrc={
                  albumData.albums.items[
                    (lightbox.photoIndex + albumData.albums.items.length - 1) %
                      albumData.albums.items.length
                  ]
                }
                onCloseRequest={() =>
                  setLightbox({
                    isOpen: false,
                  })
                }
                // onMovePrevRequest={() =>
                //   setLightbox({
                //     photoIndex:
                //       (lightbox.photoIndex +
                //         albumData.albums.items.length -
                //         1) %
                //       albumData.albums.items.length,
                //   })
                // }
                // onMoveNextRequest={() =>
                //   setLightbox({
                //     photoIndex:
                //       (lightbox.photoIndex + 1) % albumData.albums.items.length,
                //   })
                // }
              />
            )}
          </>
        ) : (
          <SpinnerWrapper>
            <img src={guy} alt="" style={{ margin: "8rem 0" }} />
          </SpinnerWrapper>
        )}
      </AlbumContainer>
    </div>
  )
}

// styled components

const SpinnerWrapper = styled.section`
  display: flex;
  justify-content: center;
`

const BackToGallery = styled.button`
  background: transparent;
  cursor: pointer;
  color: white;
  font-weight: 800;
  border: none;
  margin-left: 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: 4px;
`

const AlbumContainer = styled.div`
  flex-wrap: wrap;
  align-items: stretch;
  margin: 1rem auto;
  justify-content: center;
`
const AlbumImage = styled.img`
  width: 50%;
  object-fit: cover;
  height: 200px;
  padding: 4px;
  @media (min-width: 480px) {
    width: calc(33.33% - (0.5rem * 2));
    margin: 0.5rem;
  }

  @media (min-width: 767px) {
    width: calc(25% - (0.5rem * 2));
    margin: 0.5rem;
  }
  @media (min-width: 1200px) {
    width: calc(20% - (0.5rem * 2));
    margin: 0.5rem;
  }
`
